import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "pagina-nao-encontrada",
      "style": {
        "position": "relative"
      }
    }}>{`Página não encontrada`}</h1>
    <p>{`A página que você está procurando foi removida ou realocada.`}</p>
    <p>{`Tente usar a barra de pesquisa para localizá-lo.`}</p>
    <p><a parentName="p" {...{
        "href": "/"
      }}>{`Voltar`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      